var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"profile"},[_vm._m(0),_c('div',{staticClass:"profile-content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-4 col-md-3 align-self-center",class:{'text-center': _vm.breakpoint === 'xs'}},[_c('div',{staticClass:"profile-photo-container"},[(_vm.influencer.photo)?_c('img',{staticClass:"profile-photo img-fluid",attrs:{"src":_vm.influencer.photo}}):_c('img',{staticClass:"profile-photo img-fluid",attrs:{"src":require("../../assets/images/sample_user_profile.png")}})])]),_c('div',{staticClass:"col-12 col-sm-8 col-md-9 align-self-center wb",class:{'text-center': _vm.breakpoint === 'xs'}},[_c('p',{staticClass:"name mb-0"},[_vm._v(" "+_vm._s(_vm.influencer.nickname)+" ")]),_c('p',{staticClass:"mb-0"},[_c('b',[_vm._v("Email:")]),_vm._v(" "+_vm._s(_vm.influencer.email)+" ")]),(_vm.influencer.bio != null)?_c('p',{staticClass:"mb-0"},[_c('b',[_vm._v("Bio:")]),_vm._v(" "+_vm._s(_vm.influencer.bio)+" ")]):_vm._e(),_c('p',{staticClass:"mb-0"},[_c('b',[_vm._v("Broadcast Room: ")]),_c('router-link',{attrs:{"to":{
                            name: 'broadcast',
                            params: {
                              roomName: _vm.roomName
                            }
                          }}},[_c('b-button',{staticClass:"btn btn-h3-small btn-success pt-0 pb-0 pl-3 pr-3"},[_vm._v(" Click Here ")])],1)],1)])])])]),_c('div',[_c('hr'),_c('broadcast-slots',{attrs:{"influencer":_vm.influencer.id,"tz":_vm.userTimezone,"userType":_vm.userType}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"profile-header"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col align-self-center"},[_c('p',{staticClass:"big text-white mb-0"},[_vm._v("Influencer")])])])])
}]

export { render, staticRenderFns }