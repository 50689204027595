<template>
  <div class="container-fluid">
    <!-- Profile Info -->
    <div class="profile">
      <div class="profile-header">
        <div class="row">
          <div class="col align-self-center">
            <p class="big text-white mb-0">Influencer</p>
          </div>
        </div>
      </div>

      <div class="profile-content">
        <div class="row">
          <!-- Profile Photo -->
          <div class="col-12 col-sm-4 col-md-3 align-self-center"
               :class="{'text-center': breakpoint === 'xs'}">
            <div class="profile-photo-container">
              <img class="profile-photo img-fluid"
                   :src="influencer.photo"
                   v-if="influencer.photo" />

              <img class="profile-photo img-fluid"
                   src="../../assets/images/sample_user_profile.png"
                   v-else />
            </div>
          </div>

          <!-- Profile Info -->
          <div class="col-12 col-sm-8 col-md-9 align-self-center wb"
               :class="{'text-center': breakpoint === 'xs'}">
            <p class="name mb-0"> {{ influencer.nickname }} </p>
            <p class="mb-0">
              <b>Email:</b> {{ influencer.email }}
            </p>
            <p class="mb-0"
               v-if="influencer.bio != null">
              <b>Bio:</b> {{ influencer.bio }}
            </p>
            <p class="mb-0">
              <b>Broadcast Room: </b>
              <router-link :to="{
                              name: 'broadcast',
                              params: {
                                roomName: roomName
                              }
                            }">
                <b-button class="btn btn-h3-small btn-success
                  pt-0 pb-0 pl-3 pr-3">
                  Click Here
                </b-button>
              </router-link>
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Broadcast Slots -->
    <div>
      <hr>
      <broadcast-slots :influencer="influencer.id"
                       :tz="userTimezone"
                       :userType="userType">
      </broadcast-slots>
    </div>
  </div>
</template>

<script>
  const BroadcastSlots = () =>
  import('@/components/user/profile/BroadcastSlots.vue');
  const ChangePassword = () => import('@/components/modals/ChangePassword.vue');
  import BreakpointMixin from '@/mixins/BreakpointMixin';

  export default {
    name       : 'InfluencerProfile',
    components : {
      BroadcastSlots,
      // eslint-disable-next-line vue/no-unused-components
      ChangePassword,
    },
    data() {
      return {
        selected   : {},
        influencer : {
          id       : parseInt(this.$route.params.influencerId),
          nickname : null,
          email    : null,
          roomName : null,
          bio      : null,
          photo    : null,
        },
        userTimezone : this.$store.getters['user/user'].timezone,
        userType     : this.$store.getters['user/user'].user_type,
      }
    },
    computed : {
      roomName() {
        return this.selected.room_name;
      },
    },
    mounted() {
      this.selected = JSON.parse(localStorage.selectedInfluencer);

      // Assign Values
      this.influencer.nickname = this.selected.nickname;
      this.influencer.email = this.selected.email;
      this.influencer.roomName = this.selected.room_name;

      // Get Additional Data
      this.getUserData(this.selected.user_id);
    },
    methods : {

      /**
       * Get User's Data via User ID
       * @param userId User's assigned H3 Account ID
       */
      getUserData(userId){
        this.$http.get('api/user', {
          params : {
            id : userId,
          },
        }).then(response => {
          const influencer = response.data;

          this.influencer.bio = influencer.bio;
          this.influencer.photo = influencer.profile_img_url;
        });
      },
    },
    mixins : [
      BreakpointMixin,
    ],
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/components/user/influencer-profile";
</style>

